@if (colorBand) {
<div [style.--color-band-color]="colorBand" class="card-with-border" [matTooltip]="colorBandTooltip ?? ''"></div>
}
<div
  [ngClass]="colorBand ? 'container-band' : 'container-no-band'"
  class="container"
  [style.row-gap]="rowGap ?? '2px'"
  [style.padding-top]="(paddingYpx ?? 12) + 'px'"
  [style.padding-bottom]="(paddingYpx ?? 12) + 'px'"
  [style.padding-right]="(paddingXpx ?? 16) + 'px'"
  [style.padding-left]="(paddingXpx ?? 16) - (colorBand ? 2 : 0) + 'px'"
>
  <ng-container *ngTemplateOutlet="cardContent"></ng-container>
</div>

<ng-template #cardContent>
  <div style="grid-area: title">
    <ng-content select="[title]"></ng-content>
  </div>
  <div style="grid-area: title-action" class="action">
    <ng-content select="[titleAction]"></ng-content>
  </div>
  <div style="grid-area: subtitle">
    <ng-content select="[subtitle]"></ng-content>
  </div>
  <div style="grid-area: subtitle-action" class="action">
    <ng-content select="[subtitleAction]"></ng-content>
  </div>
  <div style="grid-area: content">
    <ng-content select="[content]"> </ng-content>
  </div>
  <div style="grid-area: content-action" class="action">
    <ng-content select="[contentAction]"></ng-content>
  </div>
  <div style="grid-area: actions">
    <ng-content select="[actions]"></ng-content>
  </div>
  @if(rightAction) {
  <div style="grid-area: right-action; display: flex; flex-direction: row; padding-left: 8px">
    @if(rightActionDivider) {
    <mat-divider vertical="true"></mat-divider>
    }

    <div style="display: flex; align-items: center; padding-left: 8px">
      <ng-content select="[rightAction]"></ng-content>
    </div>
  </div>
  }
</ng-template>
