import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type AvatarColorSignature = 'ACCENT' | 'PRIMARY' | 'WARN';

export type AvatarSizeSignature = 'X-SMALL' | 'SMALL' | 'MEDIUM' | 'LARGE';

@Component({
  selector: 'rcg-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() color: AvatarColorSignature = 'PRIMARY';

  @Input() size: AvatarSizeSignature = 'SMALL';

  @Input() text = '';
}
