import { gql } from '@apollo/client/core';

export const favoritesResponseFragment = gql`
  fragment favoritesFragment on view_configs_favorites {
    id
    tenant_id
    user_id
    module_id
    filters
    description
    filters_settings_id
    route_path
    category
    filters_model
    filters_setting {
      description
      id
      order_by
      ui_settings
      filters
    }
    users
    groups
    user {
      id
      full_name
    }
  }
`;

export const favoritesSubscription = gql`
  ${favoritesResponseFragment}
  subscription getFavoritesSubscription($tenantId: bigint!, $userId: Int!, $moduleId: Int!, $tenantGroups: _int4!) {
    data: view_configs_get_favorites(
      args: { tenantid: $tenantId, userid: $userId, moduleid: $moduleId, tenantgroups: $tenantGroups }
      offset: 0
      limit: 100
      order_by: { description: asc }
    ) {
      ...favoritesFragment
    }
  }
`;

export const favoritesQuery = gql`
  ${favoritesResponseFragment}
  query getFavoritesQuery($tenantId: bigint!, $userId: Int!, $moduleId: Int!, $tenantGroups: _int4!) {
    data: view_configs_get_favorites(
      args: { tenantid: $tenantId, userid: $userId, moduleid: $moduleId, tenantgroups: $tenantGroups }
      offset: 0
      limit: 100
      order_by: { description: asc }
    ) {
      ...favoritesFragment
    }
  }
`;

export const insertFavorite = gql`
  mutation InsertFavorites(
    $tenantId: bigint!
    $userId: Int!
    $filters_settings_id: Int!
    $moduleId: Int!
    $description: String!
    $filters: jsonb!
    $filtersModel: jsonb!
    $category: String
    $routePath: String
    $users: [Int!]
    $groups: [Int!]
  ) {
    data: insert_view_configs_favorites_one(
      object: {
        tenant_id: $tenantId
        user_id: $userId
        filters_settings_id: $filters_settings_id
        module_id: $moduleId
        description: $description
        filters: $filters
        filters_model: $filtersModel
        category: $category
        route_path: $routePath
        users: $users
        groups: $groups
      }
    ) {
      id
    }
  }
`;

export const updateFavorite = gql`
  mutation updateFavorites($id: bigint!, $filters: jsonb!, $filtersModel: jsonb!, $description: String!, $users: [Int!], $groups: [Int!]) {
    data: update_view_configs_favorites_by_pk(
      pk_columns: { id: $id }
      _set: { filters: $filters, filters_model: $filtersModel, description: $description, users: $users, groups: $groups }
    ) {
      id
    }
  }
`;

export const deleteFavorite = gql`
  mutation deleteFavorite($id: bigint!) {
    data: delete_view_configs_favorites_by_pk(id: $id) {
      id
    }
  }
`;

export const getFavoritesUsers = gql`
  query users($ids: [Int!]!) {
    data: identity_users(where: { id: { _in: $ids } }) {
      id
      full_name
      username
    }
  }
`;

export const getFavoritesGroups = gql`
  query identity_tenant_groups($tenant_id: bigint!, $ids: [bigint!]!) {
    data: identity_tenant_groups(limit: 10, where: { _and: [{ tenant_id: { _eq: $tenant_id } }, { id: { _in: $ids } }] }) {
      id
      description
    }
  }
`;
