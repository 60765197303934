@if(favorites&& favorites.length>0 && user(); as user){
<mat-expansion-panel style="height: 100%; display: flex; flex-direction: column">
  <mat-expansion-panel-header [style.width]="expanded ? 'auto' : '56px'">
    <mat-panel-title
      [matTooltip]="'favorites' | intl"
      [matTooltipPosition]="'right'"
      style="display: flex; gap: 32px; justify-content: start"
    >
      @if(expanded){
      <mat-icon [matBadge]="favorites.length" matListItemIcon>star</mat-icon>
      <div style="font-weight: 400">{{ 'favorites' | intl }}</div>
      }@else{
      <mat-icon [matBadge]="favorites.length" matListItemIcon>star</mat-icon>
      }
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-action-list
    [style.max-width]="expanded ? '200px' : '100%'"
    style="padding-bottom: 0 !important; padding-top: 0 !important; overflow: auto; height: 100%"
  >
    @for (favorite of favorites; track favorite.id) {
    <mat-list-item
      style="cursor: pointer; height: 32px"
      [matTooltip]="
        (favorite.user?.full_name && favorite.user?.id !== user.id
          ? favorite.description + ' (' + favorite.user?.full_name + ')'
          : favorite.description) + (favorite.category_translation ? ' (' + (favorite.category_translation | intl) + ')' : '')
      "
      [class.rcg-active-route]="favoriteRouteId() === favorite.id"
      matTooltipPosition="right"
      (click)="onFavoriteSelected(favorite)"
      (contextmenu)="openActionsMenu($event, favorite)"
    >
      @if(expanded){
      <span matListItemTitle style="margin-left: 16px">{{
        (favorite.user?.full_name && favorite.user?.id !== user.id
          ? favorite.description + '  (' + favorite.user?.full_name + ')'
          : favorite.description) + (favorite.category_translation ? ' (' + (favorite.category_translation | intl) + ')' : '')
      }}</span>
      }@else{
      <span matListItemTitle>{{ favorite.description }}</span>
      }
    </mat-list-item>
    }
  </mat-action-list>
</mat-expansion-panel>

<ng-template #actionsMenu let-favorite>
  <mat-action-list style="display: flex; flex-direction: column; width: 100%">
    <mat-list-item style="background-color: #e6e6e6" (click)="deleteFavorite(favorite)">
      <div style="display: flex; justify-content: space-between; width: 100%; gap: 16px">
        <div>{{ 'delete' | intl }}:</div>
        <div>
          {{
            (favorite.user?.full_name && favorite.user?.id !== user.id
              ? favorite.description + '  (' + favorite.user?.full_name + ')'
              : favorite.description) + (favorite.category_translation ? ' (' + (favorite.category_translation | intl) + ')' : '')
          }}
        </div>
      </div>
    </mat-list-item>
  </mat-action-list>
</ng-template>
}
