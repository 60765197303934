<ng-container *ngIf="text?.trim()">
  <div
    [ngClass]="{
      'x-small-avatar': size === 'X-SMALL',
      'small-avatar': size === 'SMALL',
      'medium-avatar': size === 'MEDIUM',
      'large-avatar': size === 'LARGE',
      'avatar-accent': color === 'ACCENT',
      'avatar-primary': color === 'PRIMARY',
      'avatar-warn': color === 'WARN'
    }"
    [matTooltip]="text"
    matTooltipPosition="above"
  >
    <div
      class="avatar-text"
      [ngClass]="{
        'large-avatar-content': size === 'LARGE',
        'medium-avatar-content': size === 'MEDIUM',
        'small-avatar-content': size === 'SMALL',
        'x-small-avatar-content': size === 'X-SMALL'
      }"
    >
      {{ text | avatarInitials }}
    </div>
  </div>
</ng-container>
