import { WhereExpression } from '@npm-libs/ng-templater';
import { SqlWhereExpression } from '../../../models';
import { DateFilterOperator } from './date-filter';
import {
  DateBetweenExpression,
  DateEqExpression,
  DateExpression,
  DateGteExpression,
  DateGtExpression,
  DateLteExpression,
  DateLtExpression,
  MonthExpression,
  PreviousMonthExpression,
  PreviousWeekExpression,
  TodayExpression,
  WeekExpression,
  YearExpression,
  YesterdayExpression,
} from './expressions';

export class DateFilterExpression {
  static createGqlExpression(
    field: string,
    operator: DateFilterOperator,
    date: Date | undefined,
    toDate: Date | undefined,
  ): WhereExpression | undefined {
    return this.getOperatorExpression(field, operator, date, toDate).createGqlExpression();
  }

  static createSqlExpression(
    field: string,
    operator: DateFilterOperator,
    date: Date | undefined,
    toDate: Date | undefined,
  ): SqlWhereExpression | undefined {
    return this.getOperatorExpression(field, operator, date, toDate).createSqlExpression();
  }

  private static getOperatorExpression(
    field: string,
    operator: DateFilterOperator,
    date: Date | undefined,
    toDate: Date | undefined,
  ): DateExpression {
    switch (operator) {
      case 'day':
        return new TodayExpression(field, operator, date);
      case 'yesterday':
        return new YesterdayExpression(field, operator, date);
      case 'week':
        return new WeekExpression(field, operator, date);
      case 'previousWeek':
        return new PreviousWeekExpression(field, operator, date);
      case 'month':
        return new MonthExpression(field, operator, date);
      case 'previousMonth':
        return new PreviousMonthExpression(field, operator, date);
      case 'year':
        return new YearExpression(field, operator, date);
      case 'eq':
        return new DateEqExpression(field, operator, date);
      case 'gt':
        return new DateGtExpression(field, operator, date);
      case 'gte':
        return new DateGteExpression(field, operator, date);
      case 'lt':
        return new DateLtExpression(field, operator, date);
      case 'lte':
        return new DateLteExpression(field, operator, date);
      case 'between':
        return new DateBetweenExpression(field, operator, date, toDate);
      default:
        throw new Error(`Unsupported date expression: ${operator}`);
    }
  }
}
