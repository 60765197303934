import { FilterConfig, FiltersDataContext } from '../models';
import { IFilter } from './base-filters';
import { DateFilter, DateFilterConfig } from './where-filters/date/date-filter';
import { LogicFilter, LogicFilterConfig } from './where-filters/logic-filter';
import { MultiSelectFilter, MultiselectFilterConfig } from './where-filters/multislect-filter';
import { NumberFilter, NumberFilterConfig } from './where-filters/number-filter';
import { NumericRangeFilter, NumericRangeFilterConfig } from './where-filters/numeric-range';
import { SelectFilter, SelectFilterConfig } from './where-filters/select-filter';
import { TextFilter, TextFilterConfig } from './where-filters/text-filter';

export function createFilter(filterConfig: FilterConfig, dataContext: FiltersDataContext): IFilter {
  switch (filterConfig.type) {
    case 'multiselect':
      return new MultiSelectFilter(filterConfig as MultiselectFilterConfig, dataContext);
    case 'date':
      return new DateFilter(filterConfig as DateFilterConfig, dataContext);
    case 'text':
      return new TextFilter(filterConfig as TextFilterConfig, dataContext);
    case 'logic':
      return new LogicFilter(filterConfig as LogicFilterConfig, dataContext);
    case 'number':
      return new NumberFilter(filterConfig as NumberFilterConfig, dataContext);
    case 'select':
      return new SelectFilter(filterConfig as SelectFilterConfig, dataContext);
    case 'numeric-range':
      return new NumericRangeFilter(filterConfig as NumericRangeFilterConfig, dataContext);
    default:
      throw Error(`Unsupported Filter: ${filterConfig.type}`);
  }
}
