import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@rcg/auth';
import { RouterStateService } from '@rcg/core';
import { IntlModule } from '@rcg/intl';
import { Subscription, filter, fromEvent, map, take } from 'rxjs';
import { Favorite } from '../../models';
import { FavoritesService } from '../../services';
@Component({
  selector: 'rcg-favorites-menu-item',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatCardModule, MatListModule, MatTooltipModule, MatExpansionModule, MatBadgeModule, IntlModule],
  templateUrl: './favorites-menu-item.component.html',
  styleUrl: './favorites-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoritesMenuItemComponent implements OnDestroy {
  @ViewChild('actionsMenu') actionsMenu?: TemplateRef<unknown>;

  @Input() favorites: Favorite[] = [];

  @Input() expanded? = false;

  @Output() selectedFavorite = new EventEmitter<Favorite>();

  constructor(
    private favoritesService: FavoritesService,
    private routerStateService: RouterStateService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private authService: AuthService,
  ) {}

  overlayRef?: OverlayRef | null;
  selectedFavoriteId = signal<number | undefined>(undefined);
  closeMenuSubscription?: Subscription;

  user = this.authService.user;

  ngOnDestroy(): void {
    this.closeMenuSubscription?.unsubscribe();
  }

  onFavoriteSelected(favorite: Favorite) {
    if (!favorite?.id || !favorite.route_path) {
      console.error(`Favorite is missing id or route_path. Id: ${favorite.id}`);
      return;
    }
    this.selectedFavoriteId.set(favorite.id);
    this.selectedFavorite.emit(favorite);
  }

  async deleteFavorite(favorite: Favorite) {
    if (!favorite?.id) return;
    await this.favoritesService.deleteFavorite(favorite.id, favorite.user_id);
    this.closeActionsMenu();
  }

  favoriteRouteId = toSignal(
    this.routerStateService.state$.pipe(
      map((state) => {
        if (!this.favorites?.length) return undefined;
        const favoriteId = state?.queryParams['favoriteId'] ? parseInt(state?.queryParams['favoriteId']) : undefined;
        if (!favoriteId || isNaN(favoriteId) || !state?.url) return undefined;
        return favoriteId;
      }),
    ),
  );

  openActionsMenu(event: MouseEvent, favorite: Favorite) {
    event.preventDefault();
    this.closeActionsMenu();

    if (!this.actionsMenu) return;

    const { x, y } = event;
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.actionsMenu, this.viewContainerRef, {
        $implicit: favorite,
      }),
    );

    this.closeMenuSubscription = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1),
      )
      .subscribe(() => this.closeActionsMenu());
  }

  closeActionsMenu() {
    this.closeMenuSubscription?.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
