<ng-container *ngIf="data; else noDataTemplate">
  <mat-card
    appearance="outlined"
    class="mat-elevation-z8"
    style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start"
  >
    <div class="circle-image" [ngStyle]="{ 'background-image': data.img ? 'url(\'' + data.img + '\');' : '' }">
      {{ data.img ? '' : data.initials() }}
    </div>
    <div class="text">
      <h3>{{ data.name ? data.name : '???' }}</h3>
      <h5 *ngIf="data.title" class="title">{{ data.title }}</h5>

      <div class="row" (click)="open('mailto:' + data.email)">
        <mat-icon class="icon">email</mat-icon>

        <h5 *ngIf="data.email" class="actionable-text">{{ data.email }}</h5>
      </div>
      <div *ngFor="let phone of data.phone" class="row" (click)="makePhoneCall(phone)">
        <mat-icon class="icon">phone</mat-icon>
        <h5 class="actionable-text">{{ phone }}</h5>
      </div>
      <div class="row" (click)="teams(data.email)">
        <mat-icon class="icon" svgIcon="microsoft_teams"> </mat-icon>
        <h5 class="actionable-text">{{ data.email }}</h5>
      </div>
    </div>
  </mat-card>
</ng-container>

<ng-template #noDataTemplate>
  <mat-card appearance="outlined">
    <h2 style="color: red">{{ 'error_no_contact_data' | intl }}</h2>
  </mat-card>
</ng-template>
