import { Injectable, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { FilterExpressions } from '@rcg/core';
import { GraphqlClientService } from '@rcg/graphql';
import { BehaviorSubject, EMPTY, Observable, catchError, map, pipe, shareReplay, switchMap, tap } from 'rxjs';
import { FiltersDialogComponent } from '../components';
import { filtersSettingsQuery } from '../gql/filters.gql';
import { Favorite, FilterFormResult, FilterFormSetting, FilterSetting } from '../models';

@Injectable()
export class FiltersService {
  private _filters$ = new BehaviorSubject<{ [key: string]: FilterExpressions }>({});

  private _formFilters: { [key: number]: FilterFormResult } = {};

  filters$: Observable<{ [key: string]: FilterExpressions }> = this._filters$
    .asObservable()
    .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  private _activeFilter = new BehaviorSubject<FilterExpressions | null | undefined>(null);

  activeFilter$ = this._activeFilter.asObservable().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  filterSettings = signal<FilterSetting[]>([]);

  constructor(public dialog: MatDialog, private graphQlClient: GraphqlClientService) {}

  openFiltersForm(filterFormSetting: FilterFormSetting) {
    this.dialog.open(FiltersDialogComponent, {
      data: filterFormSetting,
      autoFocus: false,
      height: filterFormSetting?.ui_settings?.height ?? '50%',
      width: filterFormSetting?.ui_settings?.width ?? '40%',
    });
  }

  changeFilter(filterId: number, filterFormResult: FilterFormResult) {
    this._formFilters[filterId] = filterFormResult;
    const filters = Object.assign({}, this._filters$.value);
    filters[filterId] = filterFormResult.filters;
    this._filters$.next(filters);
    this.setActiveFilter(filterFormResult.filters);
  }

  clearSelectedFilter(filterId: number) {
    delete this._formFilters[filterId];
    const filters = Object.assign({}, this._filters$.value);
    filters[filterId] = {};
    this._filters$.next(filters);
    this._activeFilter.next(null);
  }

  clearToInitalFavoriteFilter(filterId: number, favorite: Favorite) {
    const lastFormFilters = this._formFilters[filterId];
    if (!lastFormFilters) return;
    const formFilters = {
      ...lastFormFilters,
      filters: favorite.filters,
      filterFormSetting: {
        ...lastFormFilters.filterFormSetting,
        formModel: favorite.filters_model ?? {},
      } as FilterFormSetting,
    };
    this._formFilters[filterId] = formFilters;
    const allFilters = Object.assign({}, this._filters$.value);
    const newFilters = favorite.filters;
    allFilters[filterId] = newFilters;
    this._filters$.next(allFilters);
    this.setActiveFilter(newFilters);
  }

  getFilterFormSetting(id: number): FilterFormSetting | null {
    return this._formFilters[id]?.filterFormSetting ?? null;
  }

  getFilter(id: string): FilterExpressions | null {
    return this._filters$.value[id] ?? null;
  }

  setActiveFilter(filter: FilterExpressions | null | undefined) {
    this._activeFilter.next(filter);
  }

  getFiltersSettings(filterIds: number[]): Observable<FilterSetting[]> {
    return this.graphQlClient
      .subscribe<{ data?: FilterSetting[] | undefined }>({
        query: filtersSettingsQuery,
        variables: { ids: filterIds },
      })
      .pipe(map((f) => f?.data ?? []));
  }

  readonly setFiltersSettings = rxMethod<number[]>(
    pipe(
      tap(() => this.filterSettings.set([])),
      switchMap((ids) =>
        this.getFiltersSettings(ids).pipe(
          tap((settings) => this.filterSettings.set(settings)),
          catchError((err) => {
            console.error('Error loading filters settings', err);
            return EMPTY;
          }),
        ),
      ),
    ),
  );
}
