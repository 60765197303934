import { gql } from 'apollo-angular';

export const filtersSettingsQuery = gql`
  query filtersSettingsQuery($ids: [Int!]) {
    data: view_configs_filters_settings(where: { _and: [{ id: { _in: $ids } }] }) {
      id
      filters
      ui_settings
      order_by
      tenant_id
    }
  }
`;
