<mat-icon
  *ngIf="filterSetting && filterSetting.id"
  [matBadge]="count | metricNumber : 1 : true"
  [class.infinity-badge]="(count ?? 0) >= 1000000000000000000000000000000"
  [matTooltip]="iconSettings().tooltip"
  style="cursor: pointer"
  [ngStyle]="{ color: iconSettings().color }"
  (click)="openFilters(filterSetting, favoriteSettings)"
  >tune
</mat-icon>
