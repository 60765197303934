import { FormlyFieldConfig } from '@ngx-formly/core';
import { WhereExpression } from '@npm-libs/ng-templater';
import { SelectOption } from '@rcg/core';
import { tr } from '@rcg/intl';
import * as dot from 'dot-object';
import { from, map, mergeMap, take, toArray } from 'rxjs';
import { FilterConfig, FiltersDataContext, OperatorSelectOption, SqlWhereExpression, WhereFilterConfig } from '../models';

export abstract class IFilter<T extends FilterConfig = FilterConfig> {
  protected readonly _config: T;

  protected dataContext: FiltersDataContext;

  get config(): T {
    return this._config;
  }

  constructor(filter: T, dataContext: FiltersDataContext) {
    this._config = filter;
    this.dataContext = dataContext;
  }

  abstract createFields(): FormlyFieldConfig[];

  abstract getFilterDescription(data: Record<string, unknown>): string | Promise<string>;

  protected getValue<T>(data: Record<string, unknown>, propertyName: string): T | null {
    if (Object.prototype.hasOwnProperty.call(data, propertyName)) {
      return data[propertyName] as T;
    } else {
      return null;
    }
  }

  protected operatorsToSelectOptions(operators: OperatorSelectOption[]): SelectOption[] {
    if (!operators?.length) return [];
    return operators?.map(
      (o) =>
        ({
          label: o.label,
          value: o.value,
        } as SelectOption),
    );
  }

  operatorsOptionsTr$(operator: OperatorSelectOption[]) {
    return from(operator).pipe(
      mergeMap((i: OperatorSelectOption) =>
        from(tr(i.label)).pipe(
          take(1),
          map((translatedLabel) => ({
            ...i,
            label: translatedLabel,
          })),
        ),
      ),
      toArray(),
    );
  }

  optionsTr$(operator: SelectOption[]) {
    return from(operator).pipe(
      mergeMap((i: SelectOption) =>
        from(tr(i.label)).pipe(
          take(1),
          map((translatedLabel) => ({
            ...i,
            label: translatedLabel,
          })),
        ),
      ),
      toArray(),
    );
  }

  operatorsOptionsToSelectTr$(operator: OperatorSelectOption[]) {
    return this.operatorsOptionsTr$(operator).pipe(map((o) => this.operatorsToSelectOptions(o)));
  }

  protected resolveGqlVariables(vars?: Record<string, unknown>, resolveVars?: Record<string, unknown>): Record<string, unknown> {
    const variables = { ...(vars ?? {}) };
    const resolveVariables = resolveVars ?? {};

    if (!resolveVariables || Object.keys(resolveVariables).length === 0) return variables;

    Object.entries(resolveVariables).forEach((e) => {
      const [key, value] = e;

      if ((value as string) in this.dataContext) {
        variables[key] = this.dataContext[value as string];
      } else if ((value as string).startsWith('user.selectedTenant.')) {
        const strVal = (value as string).replace('user.selectedTenant.', 'tenant.'); // map to new tenant object
        const val = dot.pick(strVal, this.dataContext);
        variables[key] = val ?? null;
      } else if ((value as string) && (value as string).includes('.')) {
        const val = dot.pick(value as string, this.dataContext);
        variables[key] = val ?? null;
      }
    });

    return variables;
  }
}

export abstract class IWhereFilter<T extends WhereFilterConfig> extends IFilter<T> {
  abstract createGqlFilterExpression(data: Record<string, unknown>): WhereExpression[] | WhereExpression;

  abstract createSqlFilterExpression(data: Record<string, unknown>): SqlWhereExpression[];

  get field(): string {
    if (!this.config?.field) {
      throw Error('Filters - no field name in settings');
    }
    return this.config.field;
  }

  get fieldKey(): string {
    const fieldName = this.field;
    return fieldName.includes('.') ? fieldName.split('.').join('_') : fieldName;
  }
}
