<div class="contact-list">
    <h5 *ngIf="title">{{title}}:</h5>
    <ng-container *ngFor="let d of data; let i = index">
        <h5 class="contact" (click)="openEditDialog(d)">
            {{d.name && d.name.trim().length > 0 ? d.name : (d.email && d.email.trim().length > 0 ? d.email : '???')}}
            <br>
            <rcg-contact-card class="contact-card" [style.z-index]="cardZIndex" [data]="d">
            </rcg-contact-card>
        </h5>
    </ng-container>
</div>