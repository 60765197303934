import { Component, Inject, Input, Optional } from '@angular/core';
import { ContactCardData } from '@rcg/core';
import { CONTACT_LIB_OPTIONS, ContactLibOptions } from '../../options-injection-token';

@Component({
  selector: 'rcg-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent {
  open = window.open;

  @Input() data?: ContactCardData;

  constructor(@Inject(CONTACT_LIB_OPTIONS) @Optional() public options: ContactLibOptions | null) {}

  async makePhoneCall(callNumber: string) {
    const mpc = this.options?.makePhoneCall;

    if (!mpc) {
      console.warn(`[${ContactCardComponent}] Cannot make phone call. makePhoneCall function is not registered!`);
      return;
    }

    mpc(callNumber);
  }

  teams(email: string | null | undefined) {
    if (!email) return;
    window.open(`https://teams.microsoft.com/l/chat/0/0?users=${email}`, '_blank');
  }
}
