import { WhereExpression } from '@npm-libs/ng-templater';
import { SqlWhereExpression } from '../../../../models';
import { DateFilterOperator } from '../date-filter';

export abstract class DateExpression {
  constructor(
    protected field: string,
    protected operator: DateFilterOperator,
    protected date: Date | undefined,
    protected toDate?: Date | undefined,
  ) {}

  abstract createGqlExpression(): WhereExpression | undefined;

  abstract createSqlExpression(): SqlWhereExpression | undefined;

  protected startDay(date: Date): Date {
    const resultDate = new Date(date.getTime());
    resultDate.setHours(0, 0, 0, 0);
    return resultDate;
  }

  protected endDay(date: Date): Date {
    const resultDate = new Date(date.getTime());
    resultDate.setHours(23, 59, 59, 999);
    return resultDate;
  }
}
