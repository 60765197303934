import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IAction } from '@rcg/actions';
import { ContactModule } from '@rcg/contact';
import { ContactCardData, IPhoneCall, RcgPipesModule } from '@rcg/core';
import { ContactEditDialogComponent, ContactEditDialogData } from '@rcg/forms';
import { IntlModule } from '@rcg/intl';

@Component({
  selector: 'rcg-phone-call-header',
  templateUrl: './phone-call-header.component.html',
  styleUrls: ['./phone-call-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule, MatCardModule, ContactModule, RcgPipesModule, IntlModule],
})
export class PhoneCallHeaderComponent {
  @Output() refresh = new EventEmitter<void>();

  @Input() phoneCall?: IPhoneCall;

  @Input() actions: IAction[] = [];

  constructor(private dialog: MatDialog) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getContactCardData(title: string, { id, full_name, email, phone_mobile }: { [x: string]: any }) {
    return new ContactCardData(id, title, null, full_name, email, phone_mobile);
  }

  async addContact() {
    this.dialog.open(ContactEditDialogComponent, {
      minWidth: '300px',
      width: '600px',
      maxWidth: '80vw',
      data: {
        prefillData: {
          telephone: [this.phoneCall!.is_inbound ? this.phoneCall!.caller : this.phoneCall!.reciever],
        },
        closeOnSubmitSuccess: true,
      } as ContactEditDialogData,
    });
  }
}
