import { FormlyFieldConfig } from '@ngx-formly/core';
import { OrderByExpression } from '@npm-libs/ng-templater';
import { OrderByConfig } from '../models';

export abstract class IOrderBy<T extends OrderByConfig> {
  protected readonly config: T;

  constructor(orderByConfig: T) {
    this.config = orderByConfig;
  }

  abstract createOrderByExpression(data: Record<string, unknown>): OrderByExpression[];

  abstract createFields(): FormlyFieldConfig[];

  abstract getDescription(data: Record<string, unknown>): string;
}
