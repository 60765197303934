<ng-template #statusIconTemplate>
  <ng-container [ngSwitch]="phoneCall!.status">
    <mat-icon *ngSwitchCase="'Ringing'" [matTooltip]="'ringing' | intl" class="active-phone-call-animation">ring_volume</mat-icon>
    <mat-icon *ngSwitchCase="'Connected'" [matTooltip]="'talking' | intl" class="talking-phone-call">phone_in_talk</mat-icon>
    <mat-icon
      *ngSwitchDefault
      [matTooltip]="
        (phoneCall!.status === 'Finished' ? ('finished' | intl) : ('missed' | intl)) +
        ' ' +
        (phoneCall!.is_inbound === true ? ('incoming_call_lc' | intl) : phoneCall!.is_outbound === true ? ('outgoing_call_lc' | intl) : '')
      "
      [class]="phoneCall!.status === 'Finished' ? 'finished-phone-call' : 'missed-call'"
      >{{ phoneCall!.is_inbound === true ? 'phone_callback' : phoneCall!.is_outbound === true ? 'phone_forwarded ' : 'phone' }}</mat-icon
    >
  </ng-container>
</ng-template>

<ng-template #timeTemplate>
  <ng-container [ngSwitch]="phoneCall?.start_ringing | isToday">
    <ng-container *ngSwitchCase="true">
      {{ phoneCall!.start_ringing | date : 'shortTime' }}
    </ng-container>
    <ng-container *ngSwitchCase="false">
      {{ phoneCall!.start_ringing | date : 'short' }}
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #otherPartyNameTemplate let-switch="switch">
  <ng-container [ngSwitch]="switch">
    <div *ngSwitchCase="true">{{ phoneCall!.reciever ?? '' }}</div>
    <div *ngSwitchCase="false">{{ phoneCall!.caller ?? '' }}</div>
  </ng-container>
</ng-template>

<ng-template #noContactTemplate>
  <div class="no-contact-container">
    <h5>{{ 'unknown_contact' | intl }}</h5>
    <button mat-icon-button color="primary" (click)="addContact()">
      <mat-icon>person_add</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #dataRowTemplate let-contact="contact" let-switchRight="switchRight">
  <div class="row-space-between" style="align-items: center">
    <rcg-contact-list
      *ngIf="contact; else noContactTemplate"
      [allowEdit]="contact.allowEdit"
      [cardZIndex]="4"
      [data]="[contact.data]"
    ></rcg-contact-list>

    <ng-container *ngTemplateOutlet="otherPartyNameTemplate; context: { switch: switchRight }"></ng-container>
  </div>
</ng-template>

<mat-card *ngIf="phoneCall" appearance="outlined" class="container">
  <div class="row-space-between" style="align-items: center">
    <ng-container *ngTemplateOutlet="statusIconTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="timeTemplate"></ng-container>
  </div>

  <ng-container
    *ngTemplateOutlet="
      dataRowTemplate;
      context: {
        contact: {
          allowEdit: false,
          data: getContactCardData('Agent', {
            full_name: phoneCall.agent_name
          })
        },
        switchRight: phoneCall.is_inbound
      }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      dataRowTemplate;
      context: {
        contact: phoneCall.contact_id
          ? {
              allowEdit: true,
              data: getContactCardData('Kontakt', {
                id: phoneCall.contact_id,
                full_name: phoneCall.contact_name,
                phone_mobile: [phoneCall.is_inbound ? phoneCall.caller : phoneCall.reciever]
              })
            }
          : null,
        switchRight: phoneCall.is_outbound
      }
    "
  ></ng-container>
</mat-card>
