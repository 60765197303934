import { FormlyFieldConfig } from '@ngx-formly/core';
import { WhereExpression } from '@npm-libs/ng-templater';
import { SelectOption } from '@rcg/core';
import { tr } from '@rcg/intl';
import { combineLatest, firstValueFrom, from, map, mergeMap, take, tap, toArray } from 'rxjs';
import { SqlWhereExpression } from '../../models';
import { RawExpression, WhereFilterConfig } from '../../models/filter-configs';
import { OperatorSelectOption } from '../../models/filter-operators';
import { IWhereFilter } from '../base-filters';
import { RawExpressionFilter } from '../expressions';

export type MultiselectFilterOperator = 'in' | 'nin' | 'jsoncontains' | 'empty' | 'notEmpty';

export interface MultiselectFilterConfig extends WhereFilterConfig {
  gql: {
    query: string;
    variables?: Record<string, unknown>;
    resolveVariables?: Record<string, unknown>;
  };
  search?: {
    searchDebounceTime?: number;
    prefix?: string;
    suffix?: string;
    variable?: string;
  };
  searchValue?: {
    value?: string;
    label?: string;
  };
  operator?: OperatorSelectOption[];
  defaultOperator?: MultiselectFilterOperator;
  inWhereExpression?: RawExpression;
  ninWhereExpression?: RawExpression;
}

export class MultiSelectFilter extends IWhereFilter<MultiselectFilterConfig> {
  private get opratorFiledKey() {
    return `${this.fieldKey}_multiselect_operator`;
  }

  private get operatorOptions(): OperatorSelectOption[] {
    const defaultOptions = [
      {
        label: 'contains',
        value: 'in',
      },
      {
        label: 'not_contains',
        value: 'nin',
      },
      {
        label: 'empty_value',
        value: 'empty',
      },
      {
        label: 'not_empty_value',
        value: 'notEmpty',
      },
    ];

    const configOptions = (this.config.operator ?? []).filter((o) => ['in', 'nin', 'jsoncontains'].includes(o.value));
    return configOptions.length > 0 ? configOptions : defaultOptions;
  }

  private get defaultOperator(): MultiselectFilterOperator {
    const operator = this.config.defaultOperator ?? 'in';
    const operatorResult = this.operatorOptions.some((o) => o.value === operator) ? operator : this.operatorOptions[0].value;
    return operatorResult as MultiselectFilterOperator;
  }

  operatorOptionTr$ = from(this.operatorOptions).pipe(
    mergeMap((i: OperatorSelectOption) =>
      from(tr(i.label)).pipe(
        take(1),
        map((translatedLabel) => ({
          ...i,
          label: translatedLabel,
        })),
      ),
    ),
    toArray(),
  );

  labelTr$ = combineLatest([tr('condition_for'), tr(this.config.title)]).pipe(map(([condition, title]) => `${condition} ${title}`));

  override createFields(): FormlyFieldConfig[] {
    const gql = this.config.gql;
    const gqlVars = this.resolveGqlVariables(gql.variables, gql.resolveVariables);
    const operatorKey = this.opratorFiledKey;
    const acKey = this.fieldKey;
    return [
      {
        fieldGroupClassName: 'd-lg-flex align-items-lg-stretch',
        fieldGroup: [
          {
            className: 'col-lg-4 pe-lg-3',
            key: operatorKey,
            type: 'select',
            defaultValue: this.defaultOperator,
            props: {
              options: this.operatorOptionTr$,
            },
            expressions: {
              'props.label': this.labelTr$,
            },
            hooks: {
              onInit(field) {
                return field?.form?.get(operatorKey)?.valueChanges.pipe(
                  tap((select) => {
                    if ((select === 'empty' || select === 'notEmpty') && field?.form?.get(acKey)) {
                      field?.form?.get(acKey)?.setValue([]); // clear autocomplete value
                    }
                  }),
                );
              },
            },
          },
          {
            className: 'col-lg-8',
            key: this.fieldKey,
            type: 'autocompletemultiselect',
            expressions: {
              'props.label': tr(this.config.title),
              'props.placeholder': tr(this.config.placeholder ?? ''),
              hide: (field: FormlyFieldConfig) => {
                const val = field?.form?.get(operatorKey)?.value;
                return val === 'empty' || val === 'notEmpty';
              },
            },
            props: {
              settings: {
                field: this.config.field,
                initalValueFromModel: {
                  value: 'value',
                  label: 'label',
                },
                searchValue: {
                  value: this.config?.searchValue?.value ?? 'value',
                  label: this.config?.searchValue?.label ?? 'label',
                },
                query: gql.query,
                result: 'data',
                gqlVariables: gqlVars,
                search: {
                  searchDebounceTime: this.config?.search?.searchDebounceTime ?? 600,
                  prefix: this.config?.search?.prefix ?? '',
                  suffix: this.config?.search?.suffix ?? '',
                  variable: this.config?.search?.variable ?? 'search',
                },
                ignoreEmptySearch: false,
              },
            },
          },
        ],
      },
    ];
  }

  override createGqlFilterExpression(data: Record<string, unknown>): WhereExpression[] {
    const whereExpressions: WhereExpression[] = [];

    const value = this.getValue<SelectOption[] | null>(data, this.fieldKey);
    const operator = (data[this.opratorFiledKey] as MultiselectFilterOperator) ?? this.defaultOperator;

    if (operator === 'empty') {
      whereExpressions.push({
        operator: 'isnull',
        field: this.config.field,
        value: true,
      });
      return whereExpressions;
    }

    if (operator === 'notEmpty') {
      whereExpressions.push({
        operator: 'isnotnull',
        field: this.config.field,
        value: true,
      });
      return whereExpressions;
    }

    if (value?.length) {
      switch (operator) {
        case 'in':
        case 'nin':
          // raw expressions
          if (operator === 'in' && this.config.inWhereExpression) {
            return [
              new RawExpressionFilter(this.dataContext, this.config.inWhereExpression).createGqlExpression(value!.map((s) => s.value)),
            ] as WhereExpression[];
          }

          if (operator === 'nin' && this.config.ninWhereExpression) {
            return [
              new RawExpressionFilter(this.dataContext, this.config.ninWhereExpression).createGqlExpression(value!.map((s) => s.value)),
            ] as WhereExpression[];
          }

          whereExpressions.push({
            operator: operator,
            field: this.config.field,
            value: value.map((s) => s.value),
          });
          break;
        case 'jsoncontains':
          whereExpressions.push({
            operator: operator,
            field: this.config.field,
            value: value.map((a) => a.value),
          });
          break;
        default:
          console.error(`Multislect filter - unsupported operator: ${operator}`);
          break;
      }
    }
    return whereExpressions;
  }

  override createSqlFilterExpression(data: Record<string, unknown>): SqlWhereExpression[] {
    const whereExpressions: SqlWhereExpression[] = [];

    const value = this.getValue<SelectOption[] | null>(data, this.fieldKey);
    const operator = (data[this.opratorFiledKey] as MultiselectFilterOperator) ?? this.defaultOperator;

    if (operator === 'empty') {
      whereExpressions.push({
        operator: 'isnull',
        field: this.config.field,
        value: true,
      });
      return whereExpressions;
    }

    if (operator === 'notEmpty') {
      whereExpressions.push({
        operator: 'isnotnull',
        field: this.config.field,
        value: true,
      });
      return whereExpressions;
    }

    if (value?.length) {
      switch (operator) {
        case 'in':
        case 'nin':
          whereExpressions.push({
            operator: operator,
            field: this.config.field,
            value: value.map((s) => s!.value),
          });
          break;
        default:
          console.error(`Multislect filter - unsupported operator: ${operator}`);
          break;
      }
    }
    return whereExpressions;
  }

  override async getFilterDescription(data: Record<string, unknown>): Promise<string> {
    const value = this.getValue<SelectOption[] | null>(data, this.fieldKey);
    const operator = (data[this.opratorFiledKey] as MultiselectFilterOperator) ?? this.defaultOperator;
    if (operator === 'empty' || operator === 'notEmpty') {
      const trValue = operator === 'empty' ? await firstValueFrom(tr('empty_value')) : await firstValueFrom(tr('not_empty_value'));
      return `${this.config.title}: ${trValue}\n`;
    }
    return value && value.length > 0 ? `${this.config.title}: ${value.map((s) => s.label)?.join(', ') ?? ''}\n` : '';
  }
}
