<rcg-dialog
  *ngIf="dialogSetting.ui_settings?.dialogTitle"
  [dialogRef]="dialogRef"
  icon="tune"
  [iconTooltip]="filterFormSetting?.id ? 'Filter id: ' + filterFormSetting?.id : undefined"
  [title]="
    (dialogSetting.ui_settings.dialogTitle ?? '' | intl : { default: 'filters_and_sort' | intl }) +
    (categoryTranslation() ? ' - ' + (categoryTranslation() | intl) : '')
  "
>
  <div *ngIf="!filterFormSetting" class="error">
    <div>{{ errorMessage() }}</div>
  </div>

  <rcg-filters-form
    *ngIf="filterFormSetting"
    (submitFilter)="onFiltersSubmit($event)"
    (resetFilter)="onFiltersReset($event)"
    [filterFormSetting]="filterFormSetting"
    [focusFirstFieldOnInit]="true"
  >
  </rcg-filters-form>
</rcg-dialog>
