import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'rcg-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatDividerModule],
})
export class RcgCardComponent {
  @Input() colorBand?: string;
  @Input() paddingXpx?: number;
  @Input() paddingYpx?: number;
  @Input() rowGap?: string;
  @Input() colorBandTooltip?: string;
  @Input() rightAction?: boolean;
  @Input() rightActionDivider?: boolean = false;
}
