<div class="container" *ngIf="data" (click)="openFormDialog()">
  <div class="line"></div>
  <div class="child-line"></div>

  <div class="circle">
    <mat-icon [ngSwitch]="data.action_type" [matBadge]="data.collapses?.length ? data.collapses!.length + 1 : ''" matBadgeColor="accent">
      <span *ngSwitchCase="'I'" style="color: green">add</span>
      <span *ngSwitchCase="'U'" style="color: orange">sync_alt</span>
      <span *ngSwitchCase="'D'" style="color: red">delete</span>
      <span *ngSwitchCase="'L'" style="color: cornflowerblue">visibility</span>

      <span *ngSwitchDefault style="color: black">build</span>
    </mat-icon>
  </div>

  <div class="detail">
    <h2 class="mat-h2" style="margin-bottom: 0">
      <span *ngIf="data.action_description; else noDescriptionTemplate">{{ data.action_description }}</span>

      <ng-template #noDescriptionTemplate>
        <ng-container [ngSwitch]="data.action_type">
          <span *ngSwitchCase="'I'">{{ 'created' | intl }}</span>
          <span *ngSwitchCase="'U'">{{ 'change' | intl }}</span>
          <span *ngSwitchCase="'D'">{{ 'deleted' | intl }}</span>
          <span *ngSwitchCase="'L'">{{ 'insight' | intl }}</span>

          <span *ngSwitchDefault>???</span>
        </ng-container>
      </ng-template>

      <span *ngIf="data.new_value">
        <span>: </span>
        <pre style="display: inline">{{ data.new_value | json }}</pre>
      </span>
    </h2>

    <h4 class="mat-subtitle-2" style="opacity: 0.5">
      {{ data.user ? data.user + ', ' : '' }}
      {{ data.created_at | date : 'medium' }}
      {{ data.collapses?.length ? ' .. ' + (data.collapses![0].created_at | date : 'medium') : '' }}
    </h4>
  </div>

  <div class="trailing vl-scale-hover-trailing">
    <button mat-icon-button class="button--expand-collapse" *ngIf="data.collapses?.length" (click)="expandCollapse($event)">
      <mat-icon [style.transform]="expanded ? 'rotate(180deg)' : ''">expand_more</mat-icon>
    </button>
  </div>
</div>
