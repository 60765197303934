import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar/avatar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarInitialsPipe } from './pipes/avatar-initials.pipe';

@NgModule({
  declarations: [AvatarComponent, AvatarInitialsPipe],
  imports: [CommonModule, MatTooltipModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
