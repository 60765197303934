import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatarInitials',
})
export class AvatarInitialsPipe implements PipeTransform {
  transform(value?: string): string {
    const trimedValue = value?.trim();

    if (!trimedValue) {
      return '';
    }

    let initials = '';

    const splittedValue = trimedValue.split(' ');

    for (const split of splittedValue) {
      if (initials.length === 3) {
        break;
      }

      const trimed = split.trim();
      if (!trimed || trimed === '') {
        continue;
      }

      initials += trimed[0]?.toUpperCase() ?? '';
    }

    return initials;
  }
}
