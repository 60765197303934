import { WhereExpression } from '@npm-libs/ng-templater';
import { SqlWhereExpression } from '../../../../models';
import { DateFilterOperator } from '../date-filter';
import { DateExpression } from './base';

export class DateGtExpression extends DateExpression {
  constructor(field: string, operator: DateFilterOperator, date: Date | undefined) {
    super(field, operator, date);
  }

  override createGqlExpression(): WhereExpression | undefined {
    if (!this.date) return undefined;
    return {
      operator: 'gt',
      field: this.field,
      value: this.endDay(this.date).toISOString(),
    };
  }

  override createSqlExpression(): SqlWhereExpression | undefined {
    if (!this.date) return undefined;
    return {
      field: this.field,
      operator: this.operator,
      value: this.endDay(this.date).toISOString(),
    };
  }
}
