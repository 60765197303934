<ng-container *ngTemplateOutlet="error() ? errorTemplate : formContentTemplate"></ng-container>

<ng-template #errorTemplate>
  <div class="error">
    <div>{{ 'error' | intl }}: {{ error() }}</div>
  </div>
</ng-template>
<ng-template #formContentTemplate>
  <ng-container *ngIf="!error() && !loading() && filterFormSetting && model && form">
    <div class="title" *ngIf="title">{{ title }}</div>

    <form class="form" [formGroup]="form">
      <formly-form
        #formComponent
        class="html-field-fullscreen-parent"
        [form]="form"
        [fields]="fields"
        [model]="model"
        [options]="formOptions!"
        (modelChange)="model = $event"
      ></formly-form>

      <div class="form-buttons">
        @if(filterFormSetting.favoritesSettings?.favorite?.id){ @if(user()?.id !== filterFormSetting.favoritesSettings?.favorite?.user_id){
        <button (click)="resetForm($event)" mat-raised-button color="primary">
          {{ buttons?.clear?.label ?? ('reset' | intl) }}
        </button>
        }
        <button
          (click)="submitForm($event)"
          [disabled]="form.invalid || (!allowSubmitEmptyForm && form.pristine)"
          mat-raised-button
          color="primary"
        >
          {{
            buttons?.submit?.label ??
              (user()?.id === filterFormSetting.favoritesSettings?.favorite?.user_id ? ('save' | intl) : ('save_local' | intl))
          }}
        </button>
        }@else{
        <button (click)="resetForm($event)" mat-raised-button color="primary">
          {{ buttons?.clear?.label ?? ('reset' | intl) }}
        </button>
        <button
          (click)="submitForm($event)"
          [disabled]="form.invalid || (!allowSubmitEmptyForm && form.pristine)"
          mat-raised-button
          color="primary"
        >
          {{ buttons?.submit?.label ?? ('save' | intl) }}
        </button>
        }
      </div>
    </form>
  </ng-container>
</ng-template>
