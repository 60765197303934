import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormDialogService } from '@rcg/forms';
import { IntlModule } from '@rcg/intl';

export interface ChronologyItem {
  action_type?: 'I' | 'U' | 'D' | 'L';
  action_description?: string;
  user?: string;
  created_at?: string;
  old_value?: unknown;
  new_value?: unknown;
  form_id?: number;
  id?: number;
  collapses?: ChronologyItem[];
  expansionId?: number;
  child?: boolean;
}

@Component({
  selector: 'rcg-chronology-item',
  templateUrl: './chronology-item.component.html',
  styleUrls: ['./chronology-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatBadgeModule, MatButtonModule, IntlModule],
})
export class ChronologyItemComponent implements OnChanges {
  private readonly formDialogService = inject(FormDialogService);

  @Input() data?: ChronologyItem;

  @Input() @HostBinding('class.first') first = false;
  @Input() @HostBinding('class.last') last = false;

  @HostBinding('class.can-open') canOpen = false;
  @HostBinding('class.child') child = false;

  @Input() expanded?: boolean;
  @Output() expandOrCollapse = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (!('data' in changes)) return;
    this.canOpen = !!(this.data?.form_id && this.data.id);
    this.child = !!this.data?.child;
  }

  openFormDialog() {
    if (!this.canOpen) return;

    this.formDialogService.openForm({
      dialogTitle: `Kronologija${this.data!.action_description ? ` - ${this.data!.action_description}` : ''}`,
      formMode: 'update',
      formId: this.data!.form_id,
      formrecordId: this.data!.id,
    });
  }

  expandCollapse(event: MouseEvent) {
    event.stopPropagation();

    const expanded = !this.expanded;
    this.expanded = expanded;
    this.expandOrCollapse.emit();
  }
}
