import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormlyModule } from '@ngx-formly/core';
import { RcgFormsModule } from '@rcg/forms';
import { IntlModule } from '@rcg/intl';
import { RcgDialogComponent } from '@rcg/standalone';
import { ContactCardComponent, ContactListComponent } from './components';

@NgModule({
  declarations: [ContactCardComponent, ContactListComponent],
  exports: [ContactCardComponent, ContactListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    RcgFormsModule,
    RcgDialogComponent,
    IntlModule,
  ],
})
export class ContactModule {}
