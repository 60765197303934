import { WhereExpression } from '@npm-libs/ng-templater';
import * as dot from 'dot-object';
import { FiltersDataContext, RawExpression, SqlWhereExpression } from '../models';

export abstract class IExpression {
  protected dataContext: FiltersDataContext;

  constructor(dataContext: FiltersDataContext) {
    this.dataContext = dataContext;
  }

  abstract createGqlExpression(): WhereExpression | WhereExpression[];

  createSqlExpression(): SqlWhereExpression[] {
    return []; // default implementation
  }
}

export class RawExpressionFilter extends IExpression {
  constructor(dataContext: FiltersDataContext, protected rawExpression: RawExpression) {
    super(dataContext);
  }

  createGqlExpression(fieldValues?: unknown): WhereExpression | WhereExpression[] {
    const expression: RawExpression = JSON.parse(JSON.stringify(this.rawExpression));

    if (expression.resolveVariables && Object.keys(expression.resolveVariables).length > 0) {
      for (const [key, path] of Object.entries(expression.resolveVariables)) {
        if (key === 'fieldValues') {
          if (fieldValues) {
            this.replaceExpressionVariables(expression.whereExpression.raw as object, fieldValues, path, key);
          }
          continue;
        }

        const value = this.dataContext[`${key}`];
        if (value) {
          this.replaceExpressionVariables(expression.whereExpression.raw as object, value, path, key);
        }
      }
    }
    return expression.whereExpression;
  }

  private replaceExpressionVariables(expression: object, value: unknown, path: string, key: string): void {
    if (!value) return;
    const existValue = dot.pick(path, expression);
    if (!existValue) {
      console.error(`GetRawWhereExpression - Variable ${key}, path ${path} not found in where expression ${expression}`);
      return;
    }
    dot.str(path, value, expression);
  }
}
